const ACHIEVEMENTS = [
  {
    img: "/images/achievementsNew/01.jpg",
  },
  {
    img: "/images/achievementsNew/02.jpg",
  },
  {
    img: "/images/achievementsNew/03.jpg",
  },
  {
    img: "/images/achievementsNew/04.jpg",
  },
  {
    img: "/images/achievementsNew/05.jpg",
  },
  {
    img: "/images/achievementsNew/06.jpg",
  },
];

export default ACHIEVEMENTS;
