export const LOCATIONS = [
  {
    title: "Goa",
    img: "/images/locations/goa.png",
  },
  {
    title: "Hyderabad",
    img: "/images/locations/hyderabad.png",
  },
  {
    title: "Nagpur",
    img: "/images/locations/nagpur.jpg",
  },
  {
    title: "Kolkata",
    img: "/images/locations/kolkata.jpg",
  },
];
