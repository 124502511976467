export const NAVBAR_LINKS = [
  {
    title: "How it works?",
    path: "/how-it-works",
  },
  {
    title: "Subscriptions",
    path: "/subscriptions",
  },
  {
    title: "Franchisee",
    path: "/franchisee",
  },
  {
    title: "About Us",
    path: "/about-us",
  },
];
